<template>
  <div>
    <div class="p-schedule-visit__content__title">
      <span class="heading-4">{{ $t("visits.schedule.second.title") }}</span>
    </div>
    <v-expansion-panels v-model="panel" accordion flat multiple>
      <v-expansion-panel class="p-schedule-visit__exp-panel p-schedule-visit__exp-panel__2">
        <v-expansion-panel-header class="p-schedule-visit__content__accordion-header">
          <div class="p-schedule-visit__content__accordion-header__column">
            <span class="heading-5">Contact information *</span>
            <span class="sub-heading-1 p-schedule-visit__content__accordion-header__column__sub-header">
              Your number can be used to deliver important information. Please fill in either mobile or home phone
              number.
            </span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="p-schedule-visit__content__accordion-content ph-table_request-visit">
          <v-row>
            <v-col cols="12">
              <span>Mobile phone number</span>
              <PhoneInput v-model="phoneNumber" :errors="hasPhoneErrors" class="marginTop12" fieldName="phoneNumber" />
            </v-col>
            <v-col cols="12">
              <span>Home phone number</span>
              <PhoneInput
                v-model="homePhoneNumber"
                :errors="hasPhoneErrors"
                class="marginTop12"
                fieldName="homePhoneNumber"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel ref="panel" class="p-schedule-visit__exp-panel p-schedule-visit__exp-panel__1">
        <v-expansion-panel-header class="p-schedule-visit__content__accordion-header">
          <span class="heading-5">
            {{ $t("visits.schedule.second.generalMedicalInformation") }}
            <span class="p-schedule-visit__content__required">*</span>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          class="ph-table_request-visit p-schedule-visit__content__accordion-content p-schedule-visit__content__accordion-content_general"
        >
          <ActionField
            :value="`${(summary.bodyHeight && formatHeightValue(summary.bodyHeight.value)) || '--'}`"
            title="Height (ft)"
            @onAdd="addBodyHeight"
            @onView="onViewBodyHeight"
          />
          <ActionField
            :value="`${(summary.bodyWeight && summary.bodyWeight.value) || '--'}`"
            title="Weight (lbs)"
            @onAdd="addBodyWeight"
            @onView="onViewBodyWeight"
          />
          <ActionField
            :value="`${(summary.smokingStatus && summary.smokingStatus.value) || '--'}`"
            title="Smoking Status"
            @onAdd="addSmokingBehavior"
            @onView="onViewSmokingBehavior"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="p-schedule-visit__exp-panel p-schedule-visit__exp-panel__2">
        <v-expansion-panel-header class="p-schedule-visit__content__accordion-header">
          <div class="p-schedule-visit__content__accordion-header__column">
            <span class="heading-5">{{ $t("visits.schedule.second.pastMedicalHistory") }}</span>
            <span class="sub-heading-1 p-schedule-visit__content__accordion-header__column__sub-header">
              {{ $t("visits.schedule.second.pastMedicalHistorySub") }}
            </span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="p-schedule-visit__content__accordion-content">
          <PatientHistoryTable
            id="PMH"
            :items="patientConditions"
            place="checkout"
            tableClassName="scrollable-table table__no-border-radius"
            @change="onPMHChange"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="p-schedule-visit__exp-panel p-schedule-visit__exp-panel__3">
        <v-expansion-panel-header class="p-schedule-visit__content__accordion-header">
          <div class="p-schedule-visit__content__accordion-header__column">
            <span class="heading-5">{{ $t("visits.schedule.second.allergies") }}</span>
            <span class="sub-heading-1 p-schedule-visit__content__accordion-header__column__sub-header">
              {{ $t("visits.schedule.second.allergiesSub") }}
            </span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="p-schedule-visit__content__accordion-content__allergies">
          <PatientAllergiesView />
          <PatientAllergyEdit />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel ref="panel" class="p-schedule-visit__exp-panel p-schedule-visit__exp-panel__4">
        <v-expansion-panel-header class="p-schedule-visit__content__accordion-header">
          <span class="heading-5">
            Pharmacy
            <span class="p-schedule-visit__content__required">*</span>
            <span class="sub-heading-1 p-schedule-visit__content__accordion-header__column__sub-header">
              What is your favorite pharmacy?
            </span>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          class="ph-table_request-visit p-schedule-visit__content__accordion-content p-schedule-visit__content__accordion-content_general"
        >
          <div v-if="!activePharmacy">
            <v-row align="center" class="pa-0">
              <v-col cols="12" lg="8" md="8" sm="12">
                <p class="heading-5">Add Pharmacy</p>
                <p class="sub-heading-1 p-schedule-visit__content__accordion-header__column__sub-header text-left">
                  Pharmacy not selected yet, please add Pharmacy, it is necessary to make an order
                </p>
              </v-col>
              <v-col class="text-right" cols="12" lg="4" md="4" sm="12">
                <v-btn class="pharmacy-btn" color="primary" large outlined @click="toggleDialog">Add</v-btn>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-row align="center" class="pa-0">
              <v-col cols="12" lg="8" md="8" sm="12">
                <p class="heading-5">{{ activePharmacy.name }}</p>
                <p class="sub-heading-1 p-schedule-visit__content__accordion-header__column__sub-header text-left">
                  {{ activePharmacy.address }}
                </p>
                <p class="sub-heading-1 p-schedule-visit__content__accordion-header__column__sub-header text-left">
                  {{ activePharmacy.city }}
                </p>
                <p class="sub-heading-1 p-schedule-visit__content__accordion-header__column__sub-header text-left">
                  {{ activePharmacy.state }}
                </p>
                <p class="sub-heading-1 p-schedule-visit__content__accordion-header__column__sub-header text-left">
                  {{ activePharmacy.zipCode }}
                </p>
              </v-col>
              <v-col class="text-right" cols="12" lg="4" md="4" sm="12">
                <v-btn class="pharmacy-btn" color="primary" large outlined @click="changeActivePharmacy">Change</v-btn>
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="p-schedule-visit__exp-panel p-schedule-visit__exp-panel__5">
        <v-expansion-panel-header class="p-schedule-visit__content__accordion-header">
          <div class="p-schedule-visit__content__accordion-header__column">
            <span class="heading-5">{{ $t("visits.schedule.second.medications") }}</span>
            <span class="sub-heading-1 p-schedule-visit__content__accordion-header__column__sub-header">
              {{ $t("visits.schedule.second.medicationsSub") }}
            </span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          class="medication-spacing p-schedule-visit__content__accordion-content p-schedule-visit__content__accordion-content_medications"
        >
          <MedicationTable place="checkout" @change="onMedicationChange" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="p-schedule-visit__confirm">
      <v-checkbox
        :label="$t('visits.schedule.second.confirmAccuracy')"
        :value="confirmedAccurace"
        dense
        @change="onConfirmCheckboxChange"
      />
    </div>
    <div class="p-schedule-visit__content__btn-container">
      <PrimaryButton
        :disabled="buttonDisabled"
        :fullWidth="true"
        :loading="loading"
        :text="this.buttonText ? this.buttonText : $t(`visits.schedule.second.continue`)"
        className="primary-btn__blue"
        size="large"
        @onClick="onSubmit"
      />
    </div>
    <AddBodyWeight />
    <DrawerBodyWeight />
    <AddBodyHeight />
    <AddSmokingBehavior />
    <DrawerSmokingBehavior />
    <DrawerBodyHeight />
    <PharmacySelectTextFree
      :opened="addPharmacyModal"
      :title="pharmacyModalTitle"
      @onClose="toggleDialog"
      @onSelect="onSelectPharmacyFreeText"
    />
  </div>
</template>
<script>
import identity from "lodash/identity";
import pickBy from "lodash/pickBy";
import { mapActions, mapState } from "pinia";

import { PatientsApi } from "@/api/patients";
import MedicationTable from "@/components/Medications/Table";
import PatientAllergyEdit from "@/components/PatientAllergies/Edit";
import PatientAllergiesView from "@/components/PatientAllergies/View";
import PatientHistoryTable from "@/components/PatientHistoryTable/Table";
import PhoneInput from "@/components/shared/PhoneInput";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import ActionField from "@/components/VisitNotes/ActionField";
import PharmacySelectTextFree from "@/components/VisitNotes/PharmacySelectTextFree.vue";
import { BODY_HEIGHT_ADDED, BODY_WEIGHT_ADDED, SMOKING_BEHAVIOR_ADDED } from "@/eventBuses/monitoringEvents";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAllergiesStore } from "@/pinia-store/allergies";
import { useAuthStore } from "@/pinia-store/auth";
import { useAutoSaveStore } from "@/pinia-store/autoSave";
import { useCheckoutStore } from "@/pinia-store/checkout";
import { useConditionsStore } from "@/pinia-store/conditions";
import { useObservationsStore } from "@/pinia-store/observations";
import { usePatientStore } from "@/pinia-store/patient";
import { stateTypes, usePatientVisitScheduleStore } from "@/pinia-store/patientVisitSchedule";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";
import { errToText, feetToFeetInchesDisplay } from "@/utils/conversion";
import { isValidPhoneNumber } from "@/utils/validations";
import AddBodyHeight from "@/views/Patient/Monitoring/bodyHeight/Add";
import DrawerBodyHeight from "@/views/Patient/Monitoring/bodyHeight/Drawer";
import AddBodyWeight from "@/views/Patient/Monitoring/bodyWeight/Add";
import DrawerBodyWeight from "@/views/Patient/Monitoring/bodyWeight/Drawer";
import AddSmokingBehavior from "@/views/Patient/Monitoring/smokingBehavior/Add";
import DrawerSmokingBehavior from "@/views/Patient/Monitoring/smokingBehavior/Drawer";

export default {
  name: "PatientRequestVisitSecondStep",
  props: ["value", "mode", "buttonText"],
  components: {
    PharmacySelectTextFree,
    PhoneInput,
    DrawerSmokingBehavior,
    AddSmokingBehavior,
    AddBodyHeight,
    DrawerBodyHeight,
    DrawerBodyWeight,
    AddBodyWeight,
    PatientAllergyEdit,
    PatientAllergiesView,
    ActionField,
    PrimaryButton,
    MedicationTable,
    PatientHistoryTable,
  },
  data: () => ({
    panel: [0],
    loading: false,
    activePharmacy: null,
    pharmacyModalTitle: "Add Pharmacy",
    addPharmacyModal: false,
    phoneNumber: "",
    homePhoneNumber: "",
  }),
  computed: {
    ...mapState(usePatientVisitScheduleStore, ["confirmedAccurace", "patientId"]),
    ...mapState(useObservationsStore, ["summary"]),
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useCheckoutStore, ["allergies", "pmh"]),
    ...mapState(useConditionsStore, ["patientConditions"]),
    ...mapState(usePatientStore, ["pharmacies", "patient"]),
    hasPhoneErrors() {
      return !isValidPhoneNumber(this.phoneNumber) && !isValidPhoneNumber(this.homePhoneNumber)
        ? ["Please fill in either mobile or home phone number"]
        : [];
    },

    buttonDisabled() {
      return !this.confirmedAccurace || Boolean(this.hasPhoneErrors.length) || !this.activePharmacy;
    },
  },

  methods: {
    ...mapActions(useObservationsStore, [
      "toggleBodyWeightFormOpened",
      "toggleBodyHeightFormOpened",
      "toggleSmokingBehaviorFormOpened",
      "clearBodyWeightForm",
      "clearBodyHeightForm",
      "clearSmokingBehaviorForm",
      "toggleBodyWeightTableOpened",
      "toggleBodyHeightTableOpened",
      "toggleSmokingBehaviorTableOpened",
    ]),
    ...mapActions(useObservationsStore, { setObservationPatientId: "setPatientId" }),
    ...mapActions(usePatientStore, { setPatientPatientId: "setPatient" }),

    ...mapActions(usePatientStore, [
      "getPatientProfile",
      "getSelfReportedMedication",
      "getPatientPharmacies",
      "setPatientPharmacyActive",
      "addPatientPharmacy",
    ]),
    ...mapActions(useProfileSettingsStore, ["getBodyHeights"]),
    ...mapActions(useAllergiesStore, ["getPatientAllergies", "setAllergiesPatientId"]),
    ...mapActions(useObservationsStore, ["getSummary"]),
    ...mapActions(useCheckoutStore, [
      "setMedication",
      "setAllergies",
      "setPMH",
      "setIsSmoking",
      "setStringFieldByName",
      "setConfirmedAccurace",
    ]),
    ...mapActions(usePatientVisitScheduleStore, ["changeStep", "setStringFieldByName"]),
    ...mapActions(useConditionsStore, [
      "getPatientConditions",
      "addPatientCondition",
      "removePatientCondition",
      "setConditionsPatientId",
    ]),
    ...mapActions(useProfileSettingsStore, ["createPatientSettingsCareTeam"]),
    ...mapActions(useAutoSaveStore, ["startSave", "endSave"]),
    formatHeightValue(value) {
      return feetToFeetInchesDisplay(value);
    },
    onMedicationChange(value) {
      this.setMedication(value);
    },
    async onPMHChange(value) {
      this.setPMH(value);
      await this.startSave();
      await this.addPatientCondition(value);
      await this.getPatientConditions();
      await this.endSave();
    },
    onConfirmCheckboxChange(value) {
      this.setStringFieldByName({ value: value, fieldName: "confirmedAccurace" });
    },
    async onSubmit() {
      if (this.phoneNumber !== this.patient.phoneNumber || this.homePhoneNumber !== this.patient.homePhoneNumber) {
        try {
          await PatientsApi.patchProfile(
            this.uid,
            pickBy({ homePhoneNumber: this.homePhoneNumber, phoneNumber: this.phoneNumber }, identity),
          );
          this.changeStep(stateTypes.checkout);
        } catch (err) {
          snackBarEventBus.$emit(snackBarEventName, { message: errToText(err), type: "error" });
        }
      } else this.changeStep(stateTypes.checkout);
    },
    addBodyWeight() {
      this.clearBodyWeightForm();
      this.toggleBodyWeightFormOpened();
    },
    addSmokingBehavior() {
      this.clearSmokingBehaviorForm();
      this.toggleSmokingBehaviorFormOpened();
    },
    addBodyHeight() {
      this.clearBodyHeightForm();
      this.toggleBodyHeightFormOpened();
    },
    onViewBodyWeight() {
      this.toggleBodyWeightTableOpened();
    },
    onViewSmokingBehavior() {
      this.toggleSmokingBehaviorTableOpened();
    },
    onViewBodyHeight() {
      this.toggleBodyHeightTableOpened();
    },
    changeActivePharmacy() {
      this.pharmacyModalTitle = "Change Pharmacy";
      this.toggleDialog();
    },
    toggleDialog() {
      this.addPharmacyModal = !this.addPharmacyModal;
    },
    async onSelectPharmacy(item) {
      await this.startSave();
      this.addPharmacyModal = false;
      await this.addPatientPharmacy({ pharmacyId: item.id, primary: true });
      await this.getPatientActivePharmacy();
      await this.endSave();
    },
    async onSelectPharmacyFreeText(item) {
      await this.startSave();
      try {
        this.addPharmacyModal = false;
        const data = await this.createPatientSettingsCareTeam({ ...item, type: "pharmacy", patientId: this.uid });
        await this.setPatientPharmacyActive(data.id);
        await this.getPatientActivePharmacy();
      } catch (err) {
        snackBarEventBus.$emit(snackBarEventName, {
          message: errToText(err),
          type: "error",
        });
      } finally {
        await this.endSave();
      }
    },
    async getPatientActivePharmacy() {
      try {
        await this.getPatientPharmacies();

        const activePharmacy = this.pharmacies.find((pharmacy) => pharmacy.status === "active");
        // avoid errors for first logged user
        if (activePharmacy) this.activePharmacy = activePharmacy;
      } catch (e) {
        console.trace(e);
      }
    },
  },
  async mounted() {
    window.scrollTo({ top: 0, behavior: "auto" });
    this.loading = true;
    this.phoneNumber = this.patient?.phoneNumber || "";
    this.homePhoneNumber = this.patient?.homePhoneNumber || "";
    this.setAllergiesPatientId(this.uid);

    this.setConditionsPatientId(this.uid);
    this.setObservationPatientId(this.uid);
    await this.getPatientProfile(this.uid);

    await Promise.all([
      this.getPatientAllergies(),
      this.getPatientConditions(),
      this.getSelfReportedMedication(),
      this.getPatientActivePharmacy(),
      this.getSummary(this.uid),
    ]);

    this.loading = false;

    this.$root.$on(BODY_WEIGHT_ADDED, this.getSummary);
    this.$root.$on(SMOKING_BEHAVIOR_ADDED, this.getSummary);
    this.$root.$on(BODY_HEIGHT_ADDED, this.getSummary);
  },
  beforeDestroy() {
    this.$root.$off(BODY_WEIGHT_ADDED, this.getBodyWeight);
    this.$root.$off(BODY_WEIGHT_ADDED, this.getBodyWeight);
    this.$root.$off(SMOKING_BEHAVIOR_ADDED, this.getBodyWeight);
  },
};
</script>
<style lang="scss">
.pharmacy-btn {
  text-transform: capitalize;
  letter-spacing: normal;
  font-size: 14px !important;
  padding: 16px !important;
  border: 1px solid var(--primary);
}

.medication-spacing {
  padding: 0;
  padding-left: 10px;

  .edit-wrap {
    padding: 20px 15px;

    .v-input__slot {
      box-shadow: none !important;
      border: 1px solid #eeeff7 !important;
    }
  }

  .medication-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 15px;

    .name {
      margin-bottom: 10px;
    }
  }
}

.p-schedule-visit__content__accordion-content__allergies {
  &__input {
    padding: 0;
  }
}
</style>
